<template>
    <div class="row">
        <div class="col-12">
            <div class="row mb-3">
                <div class="col-lg-12">
                    <div class="card w-100 bg-current p-lg-3 p-2 border-0 rounded-lg d-block float-left">
                        <img :src="'/images/teacher.png'" alt="icon" class="sm-mt-2 w75 position-relative top--25 float-left mr-2 mt--1 ">
                        <h2 class="display1-size display2-md-size d-inline-block float-left mb-0 text-white fw-700"><span class="font-xssss fw-700 text-white d-block mb-1 ml-1">Welcome to</span> E-Talks </h2>
                    </div>
                </div>            
            </div> 
            <div class="row">
                <div class="col-12">
                    <div class="card d-block w-100 border-0 shadow-xss rounded-lg overflow-hidden">
                        <div class="card-body pb-0">
                            <div class="row justify-content-center" v-if="conference.length && !isLoad">
                                <div class="col-lg-12 col-xl-12 col-md-12 col-sm-12">
                                    <div class="card w-100 p-3 bg-grey p-lg-3 border-0 mb-3 rounded-lg d-block float-left" v-for="(item, index) in conference" :key="index">
                                        <div class="row mb-1">
                                            <div class="col-12">
                                                <router-link :to="{name:'MyCourseConferencesDetail', params:{idCourse: paramsId, idConference: item.id}}" class="float-left mb-0 text-current fw-700">{{item.zoom_id.topic | truncate(30, '...')}}</router-link>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12">
                                                <span class="font-xssss fw-700 text-grey-600 d-block">Agenda : {{item.zoom_id.agenda | truncate(50, '...')}}</span>
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-12 mb-2 d-flex justify-content-end">
                                                <span class="font-xssss fw-600 text-grey-500 d-block mr-1 ">Date & Time : </span>
                                                <small class="font-xssss">{{item.zoom_id.start_time | formatDateTime}}</small>
                                            </div>
                                            <div class="col-12 text-right">
                                                <router-link :to="{name:'MyCourseConferencesDetail', params:{idCourse: paramsId, idConference: item.id}}" class="btn btn-current">Start Conference</router-link>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 text-center py-3">
                                    <div class="my-3" v-show="isLoad">
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>
                                        <div class="spinner-grow text-warning mx-3" role="status">
                                            <span class="sr-only">Loading...</span>
                                        </div>

                                    </div>
                                    <img :src="'/images/empty.png'" v-show="!conference.length && !isLoad" alt="" width="300">
                                    <h1 class="text-muted" v-show="!conference.length && !isLoad">Data don't exist</h1>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div> 
</template>
<script>
import axios from 'axios'
export default {
    data(){
        return {
            conference: {},
            paramsId: this.$route.params.idCourse,
            isLoad: true
        }
    },
    created(){
        this.getConference()
    },
    methods:{
        async getConference(){
            await axios.get(`${process.env.VUE_APP_URL_API}/core/mycourse/conference?slug_course=${this.paramsId}`, {
                headers: {
                    Authorization: `Bearer ${localStorage.token}`
                }
            }).then(res => {
                this.isLoad = false
                this.conference = res.data
            }).catch((err) => {
                this.isLoad = false
                console.log(err)
            })
        },
    }
}
</script>